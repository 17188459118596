/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { useLazyGetUserInfoByIINQuery } from "../../../store/api/client.api";
import { LoadingOutlined } from "@ant-design/icons";
import { IINCheck, NumberLength } from "./util";
import { WrapperProps } from "../../DynamicForm/Wrapper/models";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import React from "react";
import AssignmentCheck from "../../AssignmentCheck";

interface InputIINProps extends Pick<WrapperProps, "field"> {
  title: string;
  onChange: (evt: string) => void;
  isRequired: boolean;
  value: any;
  submitForm: any;
  setSubmitForm: React.Dispatch<any>;
}

const InputIIN: React.FC<InputIINProps> = ({
  isRequired,
  onChange,
  title,
  value,
  setSubmitForm,
  submitForm,
  field,
}) => {
  const { t } = useTranslation();
  const [fieldError, setFieldError] = useState<boolean | null>(null);
  const [inputValue, setInputValue] = useState<string>(value);
  const [getUserByIIN, userByIINResponse] = useLazyGetUserInfoByIINQuery();
  const companyData = useAppSelector((state) => state.template.data);

  const parseIIN = (targetValue: string) => {
    const evtValue = targetValue.replace(/\D/g, "");

    if (evtValue.length === NumberLength) {
      try {
        const validIIN = IINCheck(evtValue.toString());
        setFieldError(!validIIN);

        if (validIIN) {
          getUserByIIN({
            companyId: companyData?.company.id,
            iin: evtValue.toString(),
          });
        }
      } catch (err) {}
    } else if (evtValue.length < NumberLength) {
      setFieldError(false);
    }

    if (evtValue.length <= NumberLength) {
      setInputValue(evtValue.toString());
      return evtValue.toString();
    } else if (evtValue.length > NumberLength) {
      setInputValue(evtValue.toString().substring(0, NumberLength));
      return evtValue.toString().substring(0, NumberLength);
    } else {
      setInputValue("");
      return evtValue;
    }
  };

  const handleResetForm = () => {
    setSubmitForm({});
    setInputValue("");
  };

  useEffect(() => {
    if (value !== inputValue) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (userByIINResponse.isSuccess) {
      const { gender, ...userServiceResult } = userByIINResponse.data;
      setSubmitForm({
        ...submitForm,
        ...userServiceResult,
      });
    }
  }, [userByIINResponse.isFetching]);

  return (
    <React.Fragment>
      <Form.Item
        key={field.id}
        rules={[
          {
            required: !!isRequired,
            validator(rule, value) {
              if (!value || value.replace(/\D/g, "").length < NumberLength) {
                return Promise.reject(t("step.form.input.iin.min"));
              } else if (fieldError) {
                return Promise.reject("Невалидный формат ИИН");
              }
              return Promise.resolve();
            },
          },
        ]}
        getValueFromEvent={(evt) => parseIIN(evt.target.value)}
        name={field.id}
      >
        <Input
          value={inputValue}
          placeholder={title}
          suffix={
            userByIINResponse.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
              />
            ) : (
              <></>
            )
          }
        />
      </Form.Item>
      {userByIINResponse.isSuccess &&
        inputValue.length === NumberLength &&
        !fieldError && (
          <AssignmentCheck fieldIIN={inputValue} onOk={handleResetForm} />
        )}
    </React.Fragment>
  );
};

export default InputIIN;
