import React, { useState } from "react";
import Button from "../../../components/ui/Button";
import DynamicForm from "../DynamicForm";
import Box from "../../ui/Box";
import Stepper from "../../ui/Stepper";
import Card from "../../ui/Card";
import Container from "../../ui/Container";
import { useTranslation } from "react-i18next";

interface StepperProps {
  formData: any; // Здесь должна быть структура JSON с данными формы
  onSubmit: (values: any) => Promise<void>;
  submitName: string;
  submitForm: any;
  setSubmitForm: React.Dispatch<any>;
}

const StepperForm: React.FC<StepperProps> = ({
  formData,
  onSubmit,
  submitName,
  submitForm,
  setSubmitForm,
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const currentForm = formData[currentStep];
  const [formHasErrors, setFormHasErrors] = useState(false);

  const handleFormSubmit = async (values: any) => {
    if (currentStep === formData.length - 1) {
      await onSubmit(values);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Container>
      <Stepper currentStep={currentStep} items={formData} />

      {/* <Spin tip="Загрузка..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}> */}
      <Card
        css={{
          marginTop: "12px",

          "& .ant-form-item": {
            marginBottom: "6px",
          },
        }}
      >
        <DynamicForm
          formFields={currentForm.inputs}
          onSubmit={handleFormSubmit}
          submitName={submitName}
          formHasErrors={formHasErrors}
          setFormHasErrors={setFormHasErrors}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
        >
          <Box
            css={{
              marginTop: "20px",
              textAlign: "center",
              display: "flex",
              gap: "8px",
            }}
          >
            {currentStep > 0 && (
              <Button onClick={handlePrevStep}>{t("step.form.prevBtn")}</Button>
            )}
            {currentStep < formData.length - 1 ? (
              <Button type="primary" htmlType="submit">
                {t("step.form.nextBtn")}
              </Button>
            ) : !formHasErrors ? (
              <Button type="primary" htmlType="submit">
                {submitName}
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </DynamicForm>
      </Card>
      {/* </Spin> */}
    </Container>
  );
};

export default StepperForm;
