import { FC, useEffect, useState } from "react";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import Box from "./ui/Box";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";

const items: MenuProps["items"] = [
  {
    label: "Қазақша",
    key: "kz",
    onClick: () => i18n.changeLanguage("kz"),
  },
  {
    label: "Русский",
    key: "ru",
    onClick: () => i18n.changeLanguage("ru"),
  },
];

interface HeaderProps {
  company: {
    nameRu: string;
    nameKz: string;
  };
  showPageLabel?: boolean;
}

const Header: FC<HeaderProps> = ({ company, showPageLabel = true }) => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    if (company) {
      const language = i18n.language;
      if (language === "ru") {
        setCompanyName(company.nameRu);
      }
      if (language === "kz") {
        setCompanyName(company.nameKz);
      }
    }
  }, [company]);

  return (
    <Box
      css={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
        padding: "10px 16px",
        backgroundColor: "rgb(241, 245, 248)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "56px",
      }}
    >
      <Box>
        <Box css={{ fontSize: "16px", fontWeight: "500" }}>{companyName}</Box>
        {showPageLabel && <Box>{t("offerPage.title")}</Box>}
      </Box>
      <Box
        css={{
          background: "$primary",
          borderRadius: "100%",
          padding: "12px",
          color: "$white",
        }}
      >
        <Dropdown
          overlayStyle={{
            marginTop: "10px",
          }}
          menu={{ items, style: { width: 150 } }}
          trigger={["click"]}
        >
          <Space>{i18n.language.toString().toUpperCase()}</Space>
        </Dropdown>
      </Box>
    </Box>
  );
};

export default Header;
