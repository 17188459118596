/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import { useEffect } from "react";
import React from "react";
import { useIMask } from "react-imask";
import { WrapperProps } from "../../DynamicForm/Wrapper/models";
import { useTranslation } from "react-i18next";

interface InputPhoneProps extends Pick<WrapperProps, "field"> {
  title: string;
  onChange: (evt: string) => void;
  isRequired: boolean;
  value: any;
  submitForm: any;
  setSubmitForm: React.Dispatch<any>;
}

const InputPhone: React.FC<InputPhoneProps> = ({
  isRequired,
  onChange,
  title,
  value,
  field,
}) => {
  const { t } = useTranslation();

  const maskInput = useIMask<HTMLInputElement, { mask: string }>({
    mask: "8-700-000-00-00",
  });

  useEffect(() => {
    onChange(maskInput.value);
  }, [maskInput.value]);

  return (
    <Form.Item
      key={field.id}
      rules={[
        {
          required: !!isRequired,
          validator(rule, value) {
            if (!value || value.replace(/\D/g, "").length < 11) {
              return Promise.reject(t("step.form.input.phone.required"));
            }
            return Promise.resolve();
          },
        },
      ]}
      name={field.id}
    >
      <Input
        required={isRequired}
        value={value}
        placeholder={title}
        ref={(ref) => {
          if (ref) {
            maskInput.ref.current = ref?.input;
          }
        }}
      />
    </Form.Item>
  );
};

export default InputPhone;
