/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "antd";
import React from 'react';

interface InputEmailProps {
    title: string;
    onChange: (evt: string) => void;
    isRequired: boolean;
    value: any;
    submitForm: any;
    setSubmitForm: React.Dispatch<any>;
}

const InputEmail: React.FC<InputEmailProps> = ({
    isRequired,
    onChange,
    title,
    value,
}) => {
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        onChange(evt.target.value);
    }

    return (
        <Input
            required={isRequired}
            value={value}
            placeholder={title}
            type="email"
            onChange={handleChange}
        />
    )
}

export default InputEmail;