import { Field } from "./models";

export const change = (
  lng: string,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  data: any
) => {
  setTitle(get(lng, data));
};

export const valueChangeEvent = (
  key: string,
  e: any,
  submitForm: any,
  setSubmitForm: React.Dispatch<any>
) => {
  setSubmitForm({ ...submitForm, [key]: e });
};

export const get = (lng: string, data: any): string => {
  return data[lng]
}
