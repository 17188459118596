import { combineReducers } from "@reduxjs/toolkit";
import { clientApi } from "./api/client.api";
import { templateApi } from "./api/template.api";
import templateSlice from "./slices/template.slice";

const rootReducer = combineReducers({
    [templateSlice.name]: templateSlice.reducer,

    [clientApi.reducerPath]: clientApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
})

export default rootReducer;