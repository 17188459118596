import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { FormTemplateResponse } from "../types/template.types"
import { templateApi } from "../api/template.api";

const initialState: {
    data: Omit<FormTemplateResponse, 'steps'> | null,
    fileLink: string | null,
} = {
    data: null,
    fileLink: null,
}

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setFileLink(state, action: PayloadAction<string>) {
            state.fileLink = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            templateApi.endpoints.getFormTemplate.matchFulfilled,
            (state, { payload }: PayloadAction<FormTemplateResponse>) => {
                state.data = {
                    company: payload.company,
                    template: payload.template,
                }
            }
        )
    },
})

export const { setFileLink } = templateSlice.actions;

export default templateSlice;