
export interface WrapperProps {
  field: Field;
  submitForm: any;
  setSubmitForm: React.Dispatch<any>;
}

export interface Field {
  id: string,
  type: InputTypeValue,
  isRequired: boolean,
  options: SelectOptionsDto[] | null,
  isMultiline: boolean,
  placeholder: string | null,
  enumName: string | null,
  value: any | null
  translations: any[]
  text?: any[],
  enableFormGroup: boolean,
  formGroup: Field[],
}
export interface SelectOptionsDto {
  key: string;
  options: { [key: string]: string } | null;
}

export interface DynamicFormProps {
  formFields: Field[];
  onSubmit: (values: any) => Promise<void>;
  submitName: string;
  formHasErrors: boolean,
  setFormHasErrors: React.Dispatch<React.SetStateAction<boolean>>,
  submitForm: any,
  setSubmitForm: React.Dispatch<any>
}

export enum InputTypeValue {
  ModalText = "modaltext",
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Select = 'select',
  File = 'file',
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Textarea = 'textarea',
  Email = 'email',
  Phone = 'phone',
  Password = 'password',
  Color = 'color',
  Range = 'range',
  Url = 'url',
  Month = 'month',
  Week = 'week',
  Signature = 'signature',
  PreviewLink = 'previewlink'
}