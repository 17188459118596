/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from "antd";
import React from 'react';
import { styled } from "../../../stitches.config";
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

interface InputDateStringProps {
    title: string;
    onChange: (evt: string) => void;
    isRequired: boolean;
    value: any;
    submitForm: any;
    setSubmitForm: React.Dispatch<any>;
}

const DateWrapper = styled('div', {
    '& .ant-picker': {
        width: '100%',
        padding: '10px 16px !important',
        height: '40px !important',
        backgroundColor: 'rgb(250, 251, 253) !important',
        // border: 'none !important',
        fontSize: '14px !important'
    },
});

const InputDateString: React.FC<InputDateStringProps> = ({
    isRequired,
    onChange,
    title,
    value,
}) => {
    return (
        <DateWrapper>
            <DatePicker
                locale={locale}
                value={value && dayjs(value)}
                placeholder={title}
                format={'DD.MM.YYYY'}
                onChange={(value) => {
                    if (value != null) {
                        onChange(value?.format('YYYY-MM-DD'));
                    } else {
                        onChange("")
                    }
                }}
            />
        </DateWrapper>
    )
}

export default InputDateString;
