import { Form } from "antd";
import CheckboxWrapper from "./Wrapper/CheckboxWrapper";
import { DynamicFormProps, Field, InputTypeValue } from "./Wrapper/models";
import NumberWrapper from "./Wrapper/NumberWrapper";
import RadioWrapper from "./Wrapper/RadioWrapper";
import SelectWrapper from "./Wrapper/SelectWrapper";
import SignatureWrapper from "./Wrapper/SignatureWrapper";
import StringWrapper from "./Wrapper/StringWrapper";
import ModalWrapper from "./Wrapper/ModalWrapper";
import PreviewFileLinkWrapper from "./Wrapper/PreviewFileLinkWrapper";
import { PropsWithChildren, useEffect } from "react";

import i18n from "../../i18n";

const DynamicForm: React.FC<DynamicFormProps & PropsWithChildren> = ({
  formFields,
  onSubmit,
  submitName,
  formHasErrors,
  setFormHasErrors,
  submitForm,
  setSubmitForm,
  children,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (submitForm) {
      form.setFieldsValue(submitForm);
    }
  }, [submitForm, form]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      {formFields.map((field) =>
        getFieldComponent(field, submitForm, setSubmitForm)
      )}
      {children}
    </Form>
  );
};

export const getFieldComponent = (
  field: Field,
  submitForm: any,
  setSubmitForm: React.Dispatch<any>
): JSX.Element | null => {
  if (field.type === InputTypeValue.ModalText) {
    return (
      <ModalWrapper
        field={field}
        submitForm={submitForm}
        setSubmitForm={setSubmitForm}
      />
    );
  }

  const WrapperComponent = (() => {
    switch (field.type) {
      case InputTypeValue.Number:
        return NumberWrapper;
      case InputTypeValue.Checkbox:
        return CheckboxWrapper;
      case InputTypeValue.Radio:
        return RadioWrapper;
      case InputTypeValue.Select:
        return SelectWrapper;
      case InputTypeValue.Signature:
        return SignatureWrapper;
      case InputTypeValue.Text:
        return StringWrapper;
      case InputTypeValue.Phone:
        return StringWrapper;
      case InputTypeValue.Email:
        return StringWrapper;
      case InputTypeValue.Date:
        return StringWrapper;
      case InputTypeValue.PreviewLink:
        return PreviewFileLinkWrapper;
      default:
        return null;
    }
  })();

  if (!WrapperComponent) {
    return null;
  }

  if (field.id === "iin") {
    return (
      <WrapperComponent
        key={field.id}
        field={field}
        submitForm={submitForm}
        setSubmitForm={setSubmitForm}
      />
    );
  }

  if (field.type === "phone") {
    return (
      <WrapperComponent
        key={field.id}
        field={field}
        submitForm={submitForm}
        setSubmitForm={setSubmitForm}
      />
    );
  }

  return (
    <Form.Item
      key={field.id}
      name={field.id}
      valuePropName={field.type === "checkbox" ? "checked" : "value"}
      rules={[
        {
          required: !!field.isRequired,
          validator(rule, value) {
            if (!value && field.isRequired) {
              return Promise.reject(
                new Error(i18n.t("step.form.input.required") || "")
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <WrapperComponent
        key={field.id}
        field={field}
        submitForm={submitForm}
        setSubmitForm={setSubmitForm}
      />
    </Form.Item>
  );
};

export default DynamicForm;
