import { FC, PropsWithChildren } from "react"
import Box from "../Box"

const Container: FC<PropsWithChildren> = ({
    children
}) => {
    return (
        <Box css={{ padding: '10px' }}>
            {children}
        </Box>
    )
}

export default Container;