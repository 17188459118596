import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducers";
import { clientApi } from "./api/client.api";
import { templateApi } from "./api/template.api";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([
    clientApi.middleware,
    templateApi.middleware,
  ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
