/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { change, valueChangeEvent } from "./Const";
import { WrapperProps } from "./models";
import Box from "../../ui/Box";
import {
  InputDateString,
  InputEmail,
  InputIIN,
  InputPhone,
} from "../../ui/Input";

const StringWrapper: React.FC<WrapperProps> = ({
  field,
  submitForm,
  setSubmitForm,
}) => {
  const {
    id,
    value,
    formGroup,
    enableFormGroup,
    isMultiline,
    enumName,
    ...rest
  } = field;
  const isRequired = false;
  const formValue = submitForm?.[id] || value;
  const { i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [first, setFirst] = useState(true);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement> | string) => {
    const inputValue = typeof evt === "string" ? evt : evt.target.value;
    valueChangeEvent(field.id, inputValue, submitForm, setSubmitForm);
  };

  const renderInput = useCallback(() => {
    if (field.id === "iin") {
      return (
        <InputIIN
          field={field}
          title={title}
          isRequired={field.isRequired}
          value={formValue}
          onChange={handleChange}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
        />
      );
    }

    if (field.type === "phone") {
      return (
        <InputPhone
          field={field}
          title={title}
          isRequired={isRequired}
          value={formValue}
          onChange={handleChange}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
        />
      );
    }

    if (field.type === "email") {
      return (
        <InputEmail
          title={title}
          isRequired={isRequired}
          value={formValue}
          onChange={handleChange}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
        />
      );
    }

    if (field.type === "date") {
      return (
        <InputDateString
          title={title}
          isRequired={isRequired}
          value={formValue}
          onChange={handleChange}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
        />
      );
    }

    return (
      <Input
        name={field.id}
        required={isRequired}
        value={formValue}
        placeholder={title}
        onChange={handleChange}
      />
    );
  }, [rest, title, isRequired, formValue, handleChange]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations);
    }
    i18n.on("languageChanged", (lng) =>
      change(lng, setTitle, field.translations)
    );

    return () => {
      i18n.off("languageChanged", (lng) =>
        change(lng, setTitle, field.translations)
      );
    };
  }, [field, i18n, setTitle]);

  return (
    <Box
      css={{
        "& .ant-input": {
          padding: "10px 16px !important",
          height: "40px !important",
          backgroundColor: "rgb(250, 251, 253) !important",
          // border: 'none !important',
          fontSize: "14px !important",
          borderRadius: "6px !important",
        },

        "& .ant-input-affix-wrapper": {
          // border: 'none',
          backgroundColor: "rgb(250, 251, 253)",
          padding: "0 4px 0 0",
        },
      }}
    >
      <Box
        css={{
          color: "$base900",
          mb: "8px",
        }}
      >
        {title}
      </Box>
      {renderInput()}
    </Box>
  );
};

export default StringWrapper;
