import { createStitches, PropertyValue } from '@stitches/react';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      primary: '#2662f0',
      base900: '#414d55',
      base100: 'rgb(250, 251, 253)',
      base200: '#f1f5f8',
      white: '#fff',
      black: '#000',
    },
  },
  utils: {
    mt: (value: PropertyValue<'marginTop'>) => ({ marginTop: value }),
    mb: (value: PropertyValue<'marginBottom'>) => ({ marginBottom: value }),
    m: (value: PropertyValue<'margin'>) => ({ margin: value }),
    pt: (value: PropertyValue<'paddingTop'>) => ({ paddingTop: value }),
    pb: (value: PropertyValue<'paddingBottom'>) => ({ paddingBottom: value }),
    p: (value: PropertyValue<'padding'>) => ({ padding: value }),
  },
  prefix: 'wouch-client'
});