import React, { useState } from 'react';
import { Carousel, Button } from 'antd';
import DynamicForm from '../DynamicForm';

interface CarouselFormProps {
    formData: any; // Здесь должна быть структура JSON с данными формы
    onSubmit: (values: any) => Promise<void>;
    submitName: string;
    submitForm: any
    setSubmitForm: React.Dispatch<any>
}

const CarouselForm: React.FC<CarouselFormProps> = ({ formData, onSubmit, submitName, submitForm, setSubmitForm }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { FormGenerator } = formData;
    const currentForm = FormGenerator[currentSlide];
    const [formHasErrors, setFormHasErrors] = useState(false);

    const handleFormSubmit = async (values: any) => {
        if (currentSlide === FormGenerator.length - 1) {
            await onSubmit(values);
        } else {
            setCurrentSlide((prevSlide) => prevSlide + 1);
        }
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => prevSlide - 1);
    };

    const handleNextSlide = () => {
        // Можно добавить валидацию формы перед переходом к следующему слайду
        setCurrentSlide((prevSlide) => prevSlide + 1);
    };

    const handleReset = () => {
        setCurrentSlide(0);
    };

    return (
        <div>
            <Carousel dotPosition="bottom" beforeChange={() => setCurrentSlide(0)} autoplay={false}>
                {FormGenerator.map((slide: any, index: number) => (
                    <div key={slide.Position}>
                        <h2>{slide.Name}</h2>
                        {index === currentSlide && (
                            <DynamicForm
                                formFields={slide.Members}
                                onSubmit={handleFormSubmit}
                                submitName={submitName}
                                formHasErrors={formHasErrors}
                                setFormHasErrors={setFormHasErrors}
                                submitForm={submitForm}
                                setSubmitForm={setSubmitForm}
                            />
                        )}
                    </div>
                ))}
            </Carousel>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {currentSlide > 0 && (
                    <Button style={{ marginRight: '8px' }} onClick={handlePrevSlide}>
                        Назад
                    </Button>
                )}
                {currentSlide < FormGenerator.length - 1 ? (
                    <Button type="primary" onClick={handleNextSlide}>
                        Далее
                    </Button>
                ) : (
                    (!formHasErrors ? (
                        <Button type="primary" onClick={handleFormSubmit}>
                          {submitName}
                        </Button>
                      ) : <></>)
                )}
                {currentSlide === FormGenerator.length - 1 && (
                    <Button style={{ marginLeft: '8px' }} onClick={handleReset}>
                        Сбросить
                    </Button>
                )}
            </div>
        </div>
    );
};

export default CarouselForm;
