import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Wrapper } from "./style";

import "react-circular-progressbar/dist/styles.css";
import Box from "../Box";
import { FC, useMemo } from "react";

import { StepProps } from "./types";
import Card from "../Card";
import { useTranslation } from "react-i18next";

interface StepperProps {
  currentStep: number;
  items: StepProps[];
}

const Stepper: FC<StepperProps> = ({ currentStep, items = [] }) => {
  const { t, i18n } = useTranslation();

  const getStepTitle = (stepData: StepProps) =>
    stepData.translations?.[i18n.language] || stepData.translations.ru;

  const currentStepData = useMemo(() => {
    return items[currentStep];
  }, [items, currentStep]);

  const nextStepData = useMemo(() => {
    if (items.length >= currentStep && items[currentStep]) {
      return items[currentStep + 1];
    }
    return null;
  }, [items, currentStep]);

  return (
    <Card>
      <Wrapper>
        <Box
          css={{
            ".CircularProgressbar": {
              width: "50px",
              height: "50px",
            },

            ".CircularProgressbar-text": {
              fontSize: "16px",
              fontWeight: "bold",
            },
          }}
        >
          <CircularProgressbar
            value={currentStep + 1}
            text={`${currentStep + 1} из ${items.length}`}
            maxValue={items.length}
            styles={buildStyles({
              pathColor: "#2662f0",
              strokeLinecap: "#f1f5f8",
              textSize: "22px",
              textColor: "#000",
            })}
          />
        </Box>
        <Box
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            css={{
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {getStepTitle(currentStepData)}
          </Box>
          {nextStepData && (
            <Box css={{ mt: "6px" }}>
              {t("nextStep")}: {getStepTitle(nextStepData)}
            </Box>
          )}
        </Box>
      </Wrapper>
    </Card>
  );
};

export default Stepper;
