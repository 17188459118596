import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  FormTemplateResponse,
  FormTemplateRequest,
  FormTemplateSignedRequest,
  FormTemplateSignedResponse,
} from "../types/template.types";

export const templateApi = createApi({
  reducerPath: "templateApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getFormTemplate: builder.query<FormTemplateResponse, FormTemplateRequest>({
      query: ({ companyId, templateId }) =>
        `/FormTemplate/Get?companyId=${companyId}&templateId=${templateId}`,
    }),

    checkForSignedAgreement: builder.query<
      FormTemplateSignedResponse,
      FormTemplateSignedRequest
    >({
      query: (query) => ({
        method: "POST",
        url: `/FormTemplate/CheckForSignedAgreement`,
        body: {
          ...query,
        },
      }),
    }),
  }),
});

export const {
  useGetFormTemplateQuery,
  useLazyGetFormTemplateQuery,
  useCheckForSignedAgreementQuery,
  useLazyCheckForSignedAgreementQuery,
} = templateApi;

export const selectUsersResult = templateApi.reducer;
