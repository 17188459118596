import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { ClientIINRequest, ClientIINResponse } from "../types/client.types";

export const clientApi = createApi({
    reducerPath: 'clientApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    endpoints: (builder) => ({
        getUserInfoByIIN: builder.query<ClientIINResponse, ClientIINRequest>({
            query: ({ companyId, iin }) => `/Client/UserInfoByIin?iin=${iin}&compnayId=${companyId}`,
        }),
    }),
});

export const {
    useGetUserInfoByIINQuery,
    useLazyGetUserInfoByIINQuery,
} = clientApi;