import { Radio, RadioChangeEvent } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { change, valueChangeEvent } from "./Const";
import { WrapperProps } from "./models";

const RadioWrapper: React.FC<WrapperProps> = ({ field, submitForm, setSubmitForm }) => {
  const { id, isRequired, value, options, ...rest } = field;
  const { i18n } = useTranslation();
  const [ title, setTitle ] = useState("");
  const [ first, setFirst ] = useState(true);

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations)
    }
    i18n.on("languageChanged", (lng) => change(lng, setTitle, field.translations));

    return () => {
      i18n.off('languageChanged', (lng) => change(lng, setTitle, field.translations));
    };
  }, [field, i18n, setTitle])
  
  const handleChange = (e: RadioChangeEvent) => {
    valueChangeEvent(field.id, e.target.value, submitForm, setSubmitForm);
  };

  return (
    <Radio.Group {...rest} name={title} value={value} onChange={handleChange}>
      <Radio key={"true"} value={true}>
        Да
      </Radio>
      <Radio key={"false"} value={false}>
        Нет
      </Radio>
    </Radio.Group>
  );
};

export default RadioWrapper;