import React, { MutableRefObject, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import { valueChangeEvent } from "./Const";
import { WrapperProps } from "./models";
import Box from "../../ui/Box";
import { UndoOutlined } from "@ant-design/icons";
import Button from "../../ui/Button";

import ExampleSignImg from "../../../assets/resources/img/example-sign.png";
import { useTranslation } from "react-i18next";

const SignatureWrapper: React.FC<WrapperProps> = ({
  field,
  submitForm,
  setSubmitForm,
}) => {
  const { t } = useTranslation();
  let canvas: MutableRefObject<ReactSignatureCanvas | null> = useRef(null);

  const handleEndDrawing = () => {
    if (canvas.current) {
      const signatureImage = canvas.current.toDataURL();
      // Отправка подписи на бэкенд или дальнейшая обработка
      valueChangeEvent(field.id, signatureImage, submitForm, setSubmitForm);
    }
  };

  const handleResetDrawing = () => {
    if (canvas.current) {
      canvas.current.clear();
      valueChangeEvent(field.id, null, submitForm, setSubmitForm);
    }
  };

  return (
    <React.Fragment>
      <Box css={{ display: "flex", gap: "30px", mb: "10px" }}>
        <Box css={{ mb: "8px", color: "$base900" }}>
          {t("sign.input.example")}:
        </Box>
        <Box css={{ img: { maxWidth: "100%", maxHeight: "74px" } }}>
          <img src={ExampleSignImg} alt="example sign" />
        </Box>
      </Box>

      <Box
        css={{
          mb: "8px",
          color: "$base900",
        }}
      >
        {t("sign.input.label")}:
      </Box>
      <Box
        css={{
          position: "relative",
          margin: "0 auto",

          "& .sigCanvas": {
            backgroundColor: "#f0f0f0",
            width: "100%",
            minHeight: "200px",
            maxHeight: "375px",
            borderRadius: "8px",
          },
        }}
      >
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            className: "sigCanvas",
          }}
          ref={canvas}
          onEnd={handleEndDrawing}
        />
        <Box
          css={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <Button
            onClick={handleResetDrawing}
            style={{ height: "auto" }}
            shape="circle"
            icon={<UndoOutlined />}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SignatureWrapper;
