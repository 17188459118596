import { Alert } from "antd";
import Header from "./Header";
import Box from "./ui/Box";
import Card from "./ui/Card";
import Container from "./ui/Container";
import Button from "./ui/Button";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const companyData = useAppSelector((state) => state.template.data);
  const templateName =
    i18n.language === "kz"
      ? companyData?.template?.titleKz
      : companyData?.template?.titleRu;
  const fileLink = useAppSelector((state) => state.template.fileLink);

  const handleClick = () => {
    if (companyData?.company.id && companyData?.template.id) {
      navigate(`/qr/${companyData?.company.id}/${companyData?.template.id}`);
    }
  };

  const handleDownloadFile = () => {
    if (fileLink) {
      window.open(fileLink, "_blank");
    }
  };

  return (
    <Box>
      <Header
        company={{
          nameKz: companyData?.company?.nameKz || "",
          nameRu: companyData?.company?.nameRu || "",
        }}
        showPageLabel={false}
      />
      <Container>
        <Card>
          {fileLink && (
            <Box
              css={{
                m: "16px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>{templateName}</Box>
              <Box
                onClick={handleDownloadFile}
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  cursor: "pointer",
                }}
              >
                <Box>
                  <VerticalAlignBottomOutlined
                    style={{ fontSize: "16px", color: "#2662f0" }}
                  />
                </Box>
                <Box css={{ color: "$primary" }}>
                  {t("template.file.download")}
                </Box>
              </Box>
            </Box>
          )}

          <Alert
            description={t("step.form.finish.signed")}
            type="success"
            showIcon
          />

          <Box css={{ mt: "16px" }}>
            <Button onClick={handleClick}>{t("step.form.backHomeBtn")}</Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default Success;
