import { Modal, Button } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { change } from "./Const";
import { WrapperProps } from "./models";

const ModalWrapper: React.FC<WrapperProps> = ({ field }) => {
  const { id, translations } = field;
  const { i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    change(i18n.language, setTitle, field.translations);
    change(i18n.language, setText, field.text);
    i18n.on("languageChanged", (lng) => change(lng, setTitle, field.translations));
    i18n.on("languageChanged", (lng) => change(lng, setText, field.text));

    return () => {
      i18n.off("languageChanged", (lng) => change(lng, setTitle, field.translations));
      i18n.off("languageChanged", (lng) => change(lng, setText, field.text));
    };
  }, [field, i18n, setTitle]);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={openModal}>{title}</Button>
      <Modal
        title={title}
        open={visible}
        onCancel={closeModal}
        footer={null}
      >
        {text}
      </Modal>
    </>
  );
};

export default ModalWrapper;