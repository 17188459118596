import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { change, valueChangeEvent } from "./Const";
import { WrapperProps } from "./models";
import Box from "../../ui/Box";
import { getFieldComponent } from "../DynamicForm";
import Card from "../../ui/Card";

const CheckboxWrapper: React.FC<WrapperProps> = ({ field, submitForm, setSubmitForm }) => {
  const { id, isRequired, enableFormGroup, formGroup, isMultiline, enumName, value, ...rest } = field;
  const { i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [first, setFirst] = useState(true);
  const [expandForm, setExpandForm] = useState<boolean>(false);

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations)
    }
    i18n.on("languageChanged", (lng) => change(lng, setTitle, field.translations));

    return () => {
      i18n.off('languageChanged', (lng) => change(lng, setTitle, field.translations));
    };
  }, [field, i18n, setTitle])

  const handleChange = (e: CheckboxChangeEvent) => {
    setExpandForm(e.target.checked);
    valueChangeEvent(field.id, e.target.checked, submitForm, setSubmitForm);
  };

  return (
    <Card
      css={{ boxShadow: 'none', paddingLeft: 0 }}
      panel={field.enableFormGroup ? 'load' : 'empty'}
    >
      <Checkbox
        name={field.id}
        aria-required={isRequired ? 'true' : 'false'}
        onChange={handleChange}
      >
        {title}
      </Checkbox>
      {field.enableFormGroup && expandForm && (
        <>
          <Box css={{ mt: '10px', p: '6px' }}>
            {field.formGroup.map((frm) => (
              getFieldComponent(frm, submitForm, setSubmitForm)
            ))}
          </Box>
          <Box css={{ mt: '6px', backgroundColor: '$base200', height: '2px' }} />
        </>
      )}
    </Card>
  );
};

export default CheckboxWrapper;