import { Input } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { change, valueChangeEvent } from "./Const";
import { WrapperProps } from "./models";

const NumberWrapper: React.FC<WrapperProps> = ({ field, submitForm, setSubmitForm }) => {
  const { id, isRequired, value, ...rest } = field;
  const { i18n } = useTranslation();
  const [ title, setTitle ] = useState("");
  const [ first, setFirst ] = useState(true);

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations)
    }
    i18n.on("languageChanged", (lng) => change(lng, setTitle, field.translations));

    return () => {
      i18n.off('languageChanged', (lng) => change(lng, setTitle, field.translations));
    };
  }, [field, i18n, setTitle])

  return (
    <Input
      {...rest}
      name={title}
      required={isRequired}
      type="number"
      value={value}
      placeholder={title}
      onChange={(v) => valueChangeEvent(field.id, v.target.value, submitForm, setSubmitForm)}
    />
  );
};

export default NumberWrapper;