import { styled } from "../../../stitches.config";

const Wrapper = styled('div', {
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(20, 46, 110, 0.1) 0px 1px 8px',
    borderRadius: '10px',

    variants: {
        panel: {
            empty: {
                padding: 0
            },

            load: {
                padding: '12px',
            },
        },
    },

    defaultVariants: {
        panel: 'load'
    },
});

export default Wrapper;