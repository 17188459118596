import React from 'react';
import CarouselForm from './CarouselForm/CarouselForm';
import TabsForm from './TabsForm/TabsForm';
import StepperForm from './StepperForm/StepperForm';

interface FormComponentProps {
  formData: any; // Здесь должна быть структура JSON с данными формы
  onSubmit: (values: any) => Promise<void>;
  submitName: string;
  submitForm: any
  setSubmitform: React.Dispatch<any>
}

const FormComponent: React.FC<FormComponentProps> = ({ formData, onSubmit, submitName, submitForm, setSubmitform }) => {
  const { steps, tabs, carousel } = formData;

  if (steps) {
    return (
      <StepperForm formData={formData.steps} onSubmit={onSubmit} submitName={submitName} submitForm={submitForm} setSubmitForm={setSubmitform}/>
    );
  }
  if (tabs) {
    return (
      <TabsForm formData={formData.tabs} onSubmit={onSubmit} submitName={submitName} submitForm={submitForm} setSubmitForm={setSubmitform}/>
    );
  }
  if (carousel) {
    return (
      <CarouselForm formData={formData.carousel} onSubmit={onSubmit} submitName={submitName} submitForm={submitForm} setSubmitForm={setSubmitform}/>
    );
  }
  return <></>
};

export default FormComponent;