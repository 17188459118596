import { useState } from "react";
import axios from "../utils/axios.config";
import { AxiosResponse } from "axios";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

export interface FormData {
  formData: any;
}

const useFormResult = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<AxiosResponse<any> | null>(
    null
  );
  const [errorData, setErrorData] = useState<string | null>(null);

  const fetchData = async (data: FormData) => {
    try {
      setLoading(true);
      const response: AxiosResponse<any> = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Form/Post`,
        data.formData,
        {
          headers: {
            "Content-Type": "application/json",
            Language: i18n.language,
          },
        }
      );
      setResponseData(response);
    } catch (error: any) {
      notification.error({
        type: "error",
        message: error.message,
      });
      setErrorData(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { fetchData, responseData, errorData, isLoading: loading };
};

export default useFormResult;
