/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import { Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLazyCheckForSignedAgreementQuery } from "../../store/api/template.api";
import { useAppSelector } from "../../store/hooks";

interface AssignmentModalProps {
  onOk: () => void;
  fieldIIN: string;
}

const AssignmentModal: FC<AssignmentModalProps> = ({ onOk, fieldIIN }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const templateData = useAppSelector((state) => state.template.data);
  const [checkAssignedDocument, {}] = useLazyCheckForSignedAgreementQuery();

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    onOk();
  };

  const checkData = async () => {
    try {
      const companyId = templateData?.company.id;
      const templateId = templateData?.template.id;

      const response = await checkAssignedDocument({
        iin: fieldIIN,
        templateId,
        companyId,
      });
      if (response.data?.hasSignedDocument) {
        setVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkData();
  }, [fieldIIN]);

  return (
    <React.Fragment>
      {visible && (
        <Modal
          title={"Информация"}
          open={true}
          onCancel={handleCancel}
          onOk={handleOk}
          cancelText="Нет"
          okText="Да"
        >
          У вас уже есть подписанный документ. Желаете подписать заново?
        </Modal>
      )}
    </React.Fragment>
  );
};

export default AssignmentModal;
