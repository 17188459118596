import { t } from "i18next";
import { useEffect, useState, useMemo } from "react";
import useFormResult from "../hooks/useFormResult";
import { FormTemplateData } from "../hooks/useFormTemplate";
import FormComponent from "./DynamicForm/FormComponent";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useGetFormTemplateQuery } from "../store/api/template.api";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Box from "./ui/Box";
import { useAppDispatch } from "../store/hooks";
import { setFileLink } from "../store/slices/template.slice";
import Card from "./ui/Card";
import Container from "./ui/Container";
import { useTranslation } from "react-i18next";

type ErrorDataTypes = {
  ErrorKz: string;
  ErrorRu: string;
  ErrorEn: string;
};

const Main = (data: FormTemplateData) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState<any | null>(null);
  const memoizedData = useMemo(
    () => ({ companyId: data.companyId, templateId: data.templateId }),
    [data.companyId, data.templateId]
  );

  const templateData = useGetFormTemplateQuery(memoizedData);

  const dispatch = useAppDispatch();
  const { fetchData, responseData, errorData, isLoading } = useFormResult();
  const [submitForm, setSubmitform] = useState<any>();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    fetchData({
      formData: {
        companyId: data.companyId,
        templateId: data.templateId,
        ...submitForm,
      },
    });
  };

  const getErrorMessage = () => {
    const errorMsg: ErrorDataTypes[] = (templateData.error as any)?.data
      ?.errors;
    if (errorMsg.length > 0) {
      const errors: Record<string, string> = {
        kz: errorMsg?.[0].ErrorKz,
        ru: errorMsg?.[0].ErrorRu,
        en: errorMsg?.[0].ErrorEn,
      };
      return errors[i18n.language];
    }
    return "";
  };

  useEffect(() => {
    if (responseData) {
      if (responseData.data.isSuccess === true) {
        dispatch(setFileLink(responseData.data.filePath));
        navigate("/qr/success");
      }
    }
  }, [responseData, errorData]);

  useEffect(() => {
    if (templateData?.data && templateData.isSuccess) {
      setFormData(templateData.data);
    }
  }, [templateData]);

  if (templateData.isLoading) {
    return (
      <Box
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          inset: "0 0 0 0",
          position: "absolute",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </Box>
    );
  }

  return (
    <div>
      <Header
        company={{
          nameKz: formData?.company?.nameKz || "",
          nameRu: formData?.company?.nameRu || "",
        }}
      />
      {templateData.isError && (
        <Container>
          <Card>{getErrorMessage()}</Card>
        </Container>
      )}
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {templateData.isSuccess && formData && (
          <FormComponent
            formData={formData}
            onSubmit={handleSubmit}
            submitName={t("sign")}
            submitForm={submitForm}
            setSubmitform={setSubmitform}
          />
        )}
      </Spin>
    </div>
  );
};

export default Main;
