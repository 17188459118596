import "@fontsource/roboto";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import i18n from "./i18n";
import NewOfferWrapper from "./components/NewOfferWrapper";
import Success from "./components/Success";

import MetaHead from "./components/MetaHead";

const { Content } = Layout;

const APP_MODE_PROD = process.env.REACT_APP_MODE === "production";

const App = () => {
  // i18n.changeLanguage("ru");
  console.log("APP_MODE_PROD=", APP_MODE_PROD);
  return (
    <React.Fragment>
      <MetaHead />
      <Router basename={APP_MODE_PROD ? "/" : "/wouch"}>
        <Layout style={{ minHeight: "100vh", maxWidth: 800, margin: "0 auto" }}>
          <Content>
            <Routes>
              <Route
                path="/qr/:companyId/:templateId"
                element={<NewOfferWrapper />}
              />
              <Route path="/qr/success" element={<Success />} />
            </Routes>
          </Content>
        </Layout>
      </Router>
    </React.Fragment>
  );
};

export default App;
