import { styled } from "../../../stitches.config";

const Wrapper = styled('div', {
    width: '100%',
    display: 'flex',
    gap: '10px',
});

export {
    Wrapper
}