import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaHead: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet></Helmet>
    </HelmetProvider>
  );
};

export default MetaHead;
