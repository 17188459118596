import React, { useEffect, useState } from "react";
import { WrapperProps } from "./models";
import { FilePdfTwoTone, LoadingOutlined } from "@ant-design/icons";
import Box from "../../ui/Box";
import { change } from "./Const";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useAppSelector } from "../../../store/hooks";
import axios from "../../../utils/axios.config";

const PreviewFileLinkWrapper: React.FC<WrapperProps> = ({
  field,
  submitForm,
}) => {
  const { i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [first, setFirst] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [fileLink, setFileLink] = useState<string>("");

  const companyData = useAppSelector((state) => state.template.data);

  const getPdfInvoice = async () => {
    try {
      setLoader(true);
      const resp = await axios.post(
        "FormTemplate/PreviewTemplate",
        {
          templateId: companyData?.template.id,
          companyId: companyData?.company.id,
          ...submitForm,
        },
        {
          headers: {
            Language: i18n.language,
          },
        }
      );

      if (resp.data?.filePath) {
        setFileLink(resp.data?.filePath);
      }
    } catch (e) {
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleDownloadFile = () => {
    getPdfInvoice();
  };

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations);
    }
    i18n.on("languageChanged", (lng) =>
      change(lng, setTitle, field.translations)
    );

    return () => {
      i18n.off("languageChanged", (lng) =>
        change(lng, setTitle, field.translations)
      );
    };
  }, [field, i18n, setTitle]);

  useEffect(() => {
    getPdfInvoice();
  }, []);

  return (
    <Spin
      spinning={loader}
      indicator={<LoadingOutlined style={{ fontSize: 16 }} />}
    >
      <a href={fileLink} target="_blank" rel="noreferrer">
        <Box
          onClick={handleDownloadFile}
          css={{ display: "flex", alignItems: "center" }}
        >
          <FilePdfTwoTone style={{ fontSize: 20 }} />
          <Box css={{ p: "10px", color: "$primary", cursor: "pointer" }}>
            {title}
          </Box>
        </Box>
      </a>

      <Box css={{ m: "6px 0", backgroundColor: "$base200", height: "2px" }} />
    </Spin>
  );
};

export default PreviewFileLinkWrapper;
