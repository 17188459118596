import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeDataRu from './locales/locale.ru.json';
import localeDataKz from './locales/locale.kz.json';

// Настройки i18n
i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: localeDataRu,
    },
    kz: {
      translation: localeDataKz,
    },
  },
  
  lng: 'ru',
  fallbackLng: 'ru',
  
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
