import { Select } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { change, valueChangeEvent } from "./Const";
import { WrapperProps, SelectOptionsDto } from "./models";
import Box from "../../ui/Box";

const SelectWrapper: React.FC<WrapperProps> = ({ field, submitForm, setSubmitForm }) => {
  const { id, isRequired, value, options, formGroup, enableFormGroup, isMultiline, enumName, ...rest } = field;
  const { i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (first) {
      setFirst(false);
      change(i18n.language, setTitle, field.translations)
    }
    i18n.on("languageChanged", (lng) => change(lng, setTitle, field.translations));

    return () => {
      i18n.off('languageChanged', (lng) => change(lng, setTitle, field.translations));
    };
  }, [field, i18n, setTitle])

  return (
    <Box>
      <Box
        css={{
          color: '$base900',
          mb: '8px'
        }}>
        {title}
      </Box>
      <Box css={{
        '& .ant-select-selector': {
          height: '40px !important',
          backgroundColor: '$base100 !important',
          // border: 'none !important',
        },

        '& .ant-select-selection-placeholder': {
          padding: '6px 4px !important',
          fontSize: '14px',
        },

        '& .ant-select-selection-item': {
          lineHeight: '40px !important'
        },
      }}>
        <Select
          value={value || submitForm?.[field.id]}
          placeholder={title}
          aria-required={isRequired ? 'true' : 'false'}
          onChange={(v) => valueChangeEvent(field.id, v, submitForm, setSubmitForm)}
        >
          {!isRequired && <Select.Option value={null}>{title}</Select.Option>}
          {(options as SelectOptionsDto[]).map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option!.options![i18n.language]}
            </Select.Option>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default SelectWrapper;