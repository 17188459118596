import useQueryParams from '../hooks/useQueryParams';
import Main from './Main';

const NewOfferWrapper = () => {
  const { templateId, companyId } = useQueryParams();

  return (
    templateId && companyId ? (
      <Main templateId={templateId} companyId={companyId}></Main>) : (<></>)
  );
};

export default NewOfferWrapper;
