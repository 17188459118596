import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import DynamicForm from '../DynamicForm';

interface TabsFormProps {
    formData: any; // Здесь должна быть структура JSON с данными формы
    onSubmit: (values: any) => Promise<void>;
    submitName: string;
    submitForm: any
    setSubmitForm: React.Dispatch<any>
}

const TabsForm: React.FC<TabsFormProps> = ({ formData, onSubmit, submitName, submitForm, setSubmitForm }) => {
    const { FormGenerator } = formData;
    const [formHasErrors, setFormHasErrors] = useState(false);

    const handleFormSubmit = async (values: any) => {
        await onSubmit(values);
    };

    return (
        <div>
            <Tabs>
                {FormGenerator.map((tab: any, index: number) => (
                    <Tabs.TabPane key={index} tab={tab.Name}>
                        <DynamicForm
                            formFields={tab.Members}
                            onSubmit={handleFormSubmit}
                            submitName={submitName}
                            formHasErrors={formHasErrors}
                            setFormHasErrors={setFormHasErrors}
                            submitForm={submitForm}
                            setSubmitForm={setSubmitForm}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            {!formHasErrors ? (
                <Button type="primary" onClick={handleFormSubmit}>
                    {submitName}
                </Button>
            ) : <></>}
        </div>
    );
};

export default TabsForm;
