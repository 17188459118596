import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useQueryParams = () => {
  const { companyId, templateId } = useParams();

  useEffect(() => {
    // fetchData(templateId, companyId);
  }, [templateId, companyId]);

  return { templateId, companyId };
};

export default useQueryParams;