import { Button as AntdButton, ButtonProps } from 'antd';
import React from 'react';

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <AntdButton
            style={{
                width: '100%',
                height: '40px',
            }}
            {...props}
        >
            {children}
        </AntdButton>
    )
}

export default Button;








